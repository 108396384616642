import { ApplicationInsights, ICustomProperties, LoggingSeverity } from '@microsoft/applicationinsights-web';
import { wrapConsoleMethod } from './logging';

let appInsights: ApplicationInsights | null = null;

export function init() {
    if (process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING) {
        try {
            appInsights = new ApplicationInsights({
                config: {
                    connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING,
                    disableTelemetry: false,
                    enableAutoRouteTracking: false,
                    enableRequestHeaderTracking: false,
                    enableResponseHeaderTracking: false,
                    enableAjaxPerfTracking: false,
                    enableUnhandledPromiseRejectionTracking: true,
                    disableAjaxTracking: true,
                    disableFetchTracking: true,
                    disableExceptionTracking: false,
                    loggingLevelConsole: LoggingSeverity.CRITICAL,
                    loggingLevelTelemetry: LoggingSeverity.CRITICAL,
                },
            });
            appInsights.loadAppInsights();
            appInsights.trackPageViewPerformance({});

            // Override console methods
            wrapConsoleMethods();

            // Add exception filter for Google Tag
            appInsights.addTelemetryInitializer((envelope) => {
                if (envelope.baseType === 'ExceptionData') {
                    if (
                        envelope.baseData &&
                        typeof envelope.baseData.exceptions === 'undefined' &&
                        envelope.baseData.message &&
                        (envelope.baseData.message as string).includes('Cannot redefine property: googletag')
                    ) {
                        return false;
                    }
                }
                return true;
            });
        } catch (e) {
            console.error('Failed to initialize app insights', e);
        }
    }
}

function wrapConsoleMethods() {
    const methods: Array<'log' | 'info' | 'warn' | 'error'> = ['log', 'info', 'warn', 'error'];
    methods.forEach((method, index) => {
        console[method] = wrapConsoleMethod(method, (message, ...optionalParams) => {
            const customProperties = createCustomProperties(optionalParams);
            appInsights?.trackTrace({ message, severityLevel: index }, customProperties);
        });
    });
}

function createCustomProperties(optionalParams: unknown[]): ICustomProperties {
    return optionalParams.reduce<ICustomProperties>((acc, curr, index) => {
        acc[`param${(index + 1).toString()}`] = curr;
        return acc;
    }, {});
}

export function trackEvent(name: string, properties?: ICustomProperties) {
    if (process.env.NODE_ENV === 'development') {
        console.log(`Tracking event: ${name}, appInsights:defined: ${(appInsights !== null).toString()}`, properties);
    }
    appInsights?.trackEvent({ name, properties });
}
