import { Link } from '@fluentui/react-components';
import DT, { AjaxData, AjaxDataColumn, InternalSettings } from 'datatables.net-dt';
import DataTable from 'datatables.net-react';
import React, { AnchorHTMLAttributes, FC, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../node_modules/datatables.net-dt/css/dataTables.dataTables.css';
import '../../../src/datatable.css';
import { IChatMessage } from '../../libs/models/ChatMessage';
import { DataTableService } from '../../libs/services/DataTableService';
import { useOidc } from '../views/OidcProvider';

DataTable.use(DT);

interface CustomLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    href?: string;
    children?: React.ReactNode;
    message: IChatMessage;
}

const CustomLink: FC<CustomLinkProps> = React.memo(
    ({ href, children, message, ...props }) => {
        const { userManager } = useOidc();
        const { t } = useTranslation();
        const dataTablesServiceRef = useRef<DataTableService | null>(null);

        if (!dataTablesServiceRef.current) {
            dataTablesServiceRef.current = new DataTableService(userManager);
        }

        const columnsToShow = useMemo(() => {
            if (typeof children === 'string' && children.startsWith('attachment')) {
                const attachmentIndex = parseInt(children.split(':')[1], 10);
                const attachment = message.attachments?.[attachmentIndex];
                return attachment ? attachment.properties.columnsToShow.split(',') : [];
            }
            return [];
        }, [children, message.attachments]);

        const columns: AjaxDataColumn[] = useMemo(
            () =>
                columnsToShow.map((column) => ({
                    name: column,
                    data: column,
                    orderable: true,
                    searchable: true,
                    search: { value: '', regex: false },
                })),
            [columnsToShow],
        );

        const ajaxCallback = useCallback(
            (data: AjaxData, callback: (data: unknown) => void, settings: InternalSettings): void => {
                if (typeof children === 'string') {
                    const attachmentIndex = parseInt(children.split(':')[1], 10);
                    const datasetReferenceId = message.attachments?.[attachmentIndex]?.properties.datasetReferenceId;
                    if (datasetReferenceId && dataTablesServiceRef.current) {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        dataTablesServiceRef.current
                            .getTableData(data, datasetReferenceId, callback, settings)
                            .then(() => {
                                /* intentionally empty */
                            })
                            .catch(() => {
                                /* intentionally empty */
                            });
                    }
                }
            },
            [children, message.attachments],
        );

        const dataTableLanguage = useMemo(
            () => ({
                processing: t('datatable.processing'),
                search: t('datatable.search'),
                lengthMenu: t('datatable.lengthMenu'),
                info: t('datatable.info'),
                infoEmpty: t('datatable.infoEmpty'),
                infoFiltered: t('datatable.infoFiltered'),
                infoPostFix: t('datatable.infoPostFix'),
                loadingRecords: t('datatable.loadingRecords'),
                zeroRecords: t('datatable.zeroRecords'),
                emptyTable: t('datatable.emptyTable'),
                paginate: {
                    first: t('datatable.paginate.first'),
                    previous: t('datatable.paginate.previous'),
                    next: t('datatable.paginate.next'),
                    last: t('datatable.paginate.last'),
                },
                aria: {
                    sortAscending: t('datatable.aria.sortAscending'),
                    sortDescending: t('datatable.aria.sortDescending'),
                },
            }),
            [t],
        );

        const renderDataTable = useCallback(() => {
            if (columnsToShow.length > 0) {
                return (
                    <DataTable
                        className="display dataTable"
                        ajax={(data: unknown, callback: (data: unknown) => void, settings: unknown): void => {
                            ajaxCallback(data as AjaxData, callback, settings as InternalSettings);
                        }}
                        options={{
                            serverSide: true,
                            columns,
                            language: dataTableLanguage,
                            scrollX: true,
                            autoWidth: true,
                        }}
                    >
                        <thead>
                            <tr>
                                {columnsToShow.map((column, index) => (
                                    <th key={index}>{column}</th>
                                ))}
                            </tr>
                        </thead>
                    </DataTable>
                );
            }
            return (
                <Link href={href} {...props}>
                    {children}
                </Link>
            );
        }, [columnsToShow, href, props, children, columns, dataTableLanguage, ajaxCallback]);

        return renderDataTable();
    },
    (prevProps, nextProps) => {
        // Custom comparison function for React.memo
        return (
            prevProps.href === nextProps.href &&
            prevProps.children === nextProps.children &&
            prevProps.message === nextProps.message &&
            prevProps.message.attachments === nextProps.message.attachments
        );
    },
);

CustomLink.displayName = 'CustomLink';

export default CustomLink;
